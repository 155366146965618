import loadable from "@loadable/component";
import React, { Component } from "react";
import { StaticRouter } from "react-router-dom";

import "./style.scss";

const HomeView = loadable(() => import("./views/HomeView"));

class HomeRoute extends Component {
  render() {
    return (
      <div>
        <StaticRouter>
          <HomeView />
          {/* <Home /> */}
        </StaticRouter>
      </div>
    );
  }
}

HomeRoute.propTypes = {};

export default HomeRoute;
